import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";
import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const isNew = true;
const href = "/ratesheet/20240327-Rate-Sheet.pdf";
const date = "27.03.2024";

const Article = () => (
    <NewsArticleLayout href={href} date={date} isNew={isNew}>
        <h1 className="page-title">Changes to our mortgage products</h1>

        <br />
        <p>With effect from Wednesday 27 March 2024 we will be making the following changes to our range:</p>
        <p><strong>Summary of changes</strong></p>
        <p>We are pleased to be launching our Energy Efficient Homes range for UK Residential First Time Buyer, Home Mover and Remortgage customers within our Intermediary channel. The proposition will offer an additional cashback incentive, when compared with our standard range equivalent, to customers purchasing or remortgaging a property which is confirmed to have an Energy Performance Certificate (EPC) rating of A or B until further notice.</p>
        <p>All of our fixed rate end dates will be extended as below:</p>
        <ul>
          <li>2 Year Fixed &ndash; 31 July 2026</li>
          <li>3 Year Fixed &ndash; 31 July 2027</li>
          <li>5 Year Fixed &ndash; 31 July 2029</li>
          <li>10 Year Fixed &ndash; 31 July 2034</li>
        </ul>
        <p><strong>UK Residential Existing Customer Switching Range</strong></p>
        <ul>
          <li>2 Year Fixed Fee Saver at 95% LTV has decreased</li>
          <li>3 Year Fixed Fee Saver at 95% LTV has decreased</li>
          <li>5 Year Fixed Fee Saver at 90% and 95% LTV have decreased</li>
          <li>5 Year Fixed Standard at 90% LTV has decreased</li>
          <li>5 Year Fixed Premier Exclusive at 90% LTV has decreased</li>
          <li>2 Year Fixed Fee Saver at 80%, 85% and 90% LTV have increased</li>
          <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
          <li>3 Year Fixed Fee Saver at 80%, 85% and 90% LTV have increased</li>
          <li>3 Year Fixed Standard at 70% and 75% LTV have increased</li>
          <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
          <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
          <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75% 80% and 85% LTV have increased</li>
        </ul>
        <p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
        <ul>
          <li>5 Year Fixed Fee Saver at 90% LTV has decreased</li>
          <li>5 Year Fixed Standard at 90% LTV has decreased</li>
          <li>5 Year Fixed Premier Exclusive at 90% LTV has decreased</li>
          <li>2 Year Fixed Fee Saver at 80%, 85% and 90% LTV have increased</li>
          <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
          <li>3 Year Fixed Fee Saver at 80%, 85% and 90% LTV have increased</li>
          <li>3 Year Fixed Standard at 70% and 75% LTV have increased</li>
          <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
          <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
          <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75% 80% and 85% LTV have increased</li>
        </ul>
        <p><strong>UK Residential First Time Buyer Range</strong></p>
        <ul>
          <li>2 Year Fixed Fee Saver at 95% LTV has decreased</li>
          <li>3 Year Fixed Fee Saver at 95% LTV has decreased</li>
          <li>5 Year Fixed Fee Saver at 90% and 95% LTV have decreased</li>
          <li>5 Year Fixed Standard at 90% LTV has decreased</li>
          <li>5 Year Fixed Premier Exclusive at 90% LTV has decreased</li>
          <li>2 Year Fixed Fee Saver at 80%, 85% and 90% LTV have increased</li>
          <li>3 Year Fixed Fee Saver at 60%, 80%, 85% and 90% LTV have increased</li>
          <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
          <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
          <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
          <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
        </ul>
        <p><strong>UK Residential Home Mover Range</strong></p>
        <ul>
          <li>2 Year Fixed Fee Saver at 95% LTV has decreased</li>
          <li>3 Year Fixed Fee Saver at 95% LTV has decreased</li>
          <li>5 Year Fixed Fee Saver at 90% and 95% LTV have decreased</li>
          <li>5 Year Fixed Standard at 90% LTV has decreased</li>
          <li>5 Year Fixed Premier Exclusive at 90% LTV has decreased</li>
          <li>2 Year Fixed Fee Saver at 80%, 85% and 90% LTV have increased</li>
          <li>3 Year Fixed Fee Saver at 60%, 80%, 85% and 90% LTV have increased</li>
          <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
          <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
          <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
          <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
        </ul>
        <p><strong>UK Residential Remortgage Range</strong></p>
        <ul>
          <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
          <li>2 Year Fixed Standard at 70% and 75% LTV have increased</li>
          <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
          <li>3 Year Fixed Standard at 60%, 70%, 75%, 85% and 90% LTV have increased</li>
          <li>5 Year Fixed Fee Saver at 80% LTV has increased</li>
          <li>5 Year Fixed Standard at 80% and 85% LTV have increased</li>
          <li>5 Year Fixed Premier Exclusive at 80% and 85% LTV have increased</li>
          <li>10 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have increased</li>
          <li>10 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
        </ul>
        <p><strong>UK Residential Remortgage Cashback Range</strong></p>
        <ul>
          <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
          <li>2 Year Fixed Standard at 70% and 75% LTV have increased</li>
          <li>5 Year Fixed Fee Saver at 80% LTV has increased</li>
          <li>5 Year Fixed Standard at 80% and 85% LTV have increased</li>
        </ul>
        <p><strong>UK Buy to Let Existing Customer Switching Range</strong></p>
        <ul>
          <li>2 Year Fixed Fee Saver at 65% LTV has increased</li>
          <li>2 Year Fixed Standard at 65% LTV has increased</li>
          <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
          <li>5 Year Fixed Standard at 65% LTV has increased</li>
        </ul>
        <p><strong>UK Buy to Let Existing Customer Borrowing More Range</strong></p>
        <ul>
          <li>2 Year Fixed Fee Saver at 65% LTV has increased</li>
          <li>2 Year Fixed Standard at 65% LTV has increased</li>
          <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
          <li>5 Year Fixed Standard at 65% LTV has increased</li>
        </ul>
        <p><strong>UK Buy to Let Purchase Range</strong></p>
        <ul>
          <li>2 Year Fixed Fee Saver at 75% LTV has decreased</li>
          <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
        </ul>
        <p><strong>UK Buy to Let Remortgage Range</strong></p>
        <ul>
          <li>2 Year Fixed Fee Saver at 65% and 75% LTV have decreased</li>
          <li>5 Year Fixed Fee Saver at 60% and 75% LTV have decreased</li>
          <li>5 Year Fixed Standard at 60% and 75% LTV have decreased</li>
        </ul>
        <p><strong>International Residential Range</strong></p>
        <ul>
          <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
          <li>2 Year Fixed Standard at 70% and 75% LTV have increased</li>
          <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
          <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
          <li>10 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
          <li>10 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
        </ul>
        <p>There are no changes to any other interest rates.</p>
        <p>An updated mortgage rate sheet will be published on Wednesday 27 March 2024 to reflect these changes.</p>
    </NewsArticleLayout>
);

export default Article;
